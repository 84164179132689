import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetModelsService } from '../services/get-models.service';
import { EditModelService } from '../services/edit-model.service';
import { Model } from '../shared/model';
import { CropArea } from '../shared/cropArea';
import { CropperComponent } from 'angular-cropperjs';
import { CropTest } from '../shared/cropTest';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.scss']
})
export class EditModelComponent implements OnInit {

  constructor(private getModelService: GetModelsService, 
    private route: ActivatedRoute, private editModelService: EditModelService) { }

  model_id: string;
  model: Model;
  errMess:string;
  imageUrl: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  cname: string;
  crop: CropArea;
  testCropObj: CropTest;
  testPreProcessObj: {};
  ocrText: string;
  preProcessChecked: boolean = false;
  noiseReductionChecked: boolean = false;
  noise: number = 50;
  processedImage: string;
  showSpinner:boolean=false;

  @ViewChild('cropName') public cropName:ElementRef;
  

  ngOnInit(): void {
    this.model_id = this.route.snapshot.queryParamMap.get('model_id');
    //console.log(this.model_id);
    this.getModelService.getModelfromID(this.model_id)
    .subscribe((model)=>{
      this.model=model;
      //console.log(model);
      this.imageUrl = model.image;
    },
    (errmess)=>this.errMess=<any>errmess);
  }
  
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  imgUrl = null;
  config = {
    background : false,
    movable: true,
    rotatable : true,
    scalable: true,
    zoomable: false, 
    viewMode: 1,
    checkImageOrigin : true,
    cropmove:this.cropMoved.bind(this),
    checkCrossOrigin: true
  };
  
  dialogDisplay: boolean = false;

  showDialog() {
      this.dialogDisplay = true;
      this.cname = this.cropName.nativeElement.value;

  }

  parameterDisplay: boolean = false;

  showParameter() {
    this.parameterDisplay = true;

}

  save(){
    this.dialogDisplay = false;

    var result = this.model.values.find(obj => {
      return obj.fieldName.localeCompare(this.cname, undefined, { sensitivity: 'base' }) === 0;
    })

    console.log(result);
    this.crop = {
      model_id: this.model_id,
      values: [
        {
          fId: result==undefined ? undefined: result.fId,
          fieldName: this.cname,
          fieldValue: {
            x1: this.x1,
            y1: this.y1,
            x2: this.x2,
            y2: this.y2,
            toPre: this.preProcessChecked,
            toNR: this.noiseReductionChecked,
            nRparam: this.noise
          }
        }
      ]
   
    }

    console.log(this.crop);
    
    this.editModelService.putCrop(this.crop)
    .subscribe((dafa)=>{
      this.cropName.nativeElement.value = "";
      console.log(dafa);
      this.ngOnInit();
      
    },
      errmess=>{
        this.errMess=<any>errmess;
      });
  }

  preProcessedOP: boolean = false;

  testPreProcess(){
    this.showSpinner=true;
    this.testPreProcessObj = {
      model_id : this.model_id,
      x1: this.x1,
      y1: this.y1,
      x2: this.x2,
      y2: this.y2,
      noiseReduce: this.noiseReductionChecked,
      noiseReduceParam: this.noise

    }
    console.log(this.testPreProcessObj);
    this.editModelService.testPreProcess(this.testPreProcessObj)
    .subscribe((processedImg)=>{
      console.log(processedImg);

      this.processedImage = "data:image/png;base64," + processedImg[0].processedImage;
      this.preProcessedOP = true;
      this.showSpinner=false;
    },
      errmess=>{
        this.errMess=<any>errmess;
    });


  }

  ocrDisplay: boolean = false;

  testCrop(){
    
    this.showSpinner=true
    this.testCropObj = {
      model_id : this.model_id,
      x1: this.x1,
      y1: this.y1,
      x2: this.x2,
      y2: this.y2,
      toPre: this.preProcessChecked,
      toNR: this.noiseReductionChecked,
      nRparam: this.noise
    }
    console.log(this.testCropObj);
    this.editModelService.testCrop(this.testCropObj)
    .subscribe((ocrtext)=>{
      console.log(ocrtext);
      this.ocrText = ocrtext[0].msg;
      this.ocrDisplay = true;
      this.showSpinner=false;
    },
      errmess=>{
        this.errMess=<any>errmess;
    });

    
  }

  cropMoved(data){
    this.imgUrl = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.x1 = this.angularCropper.cropper.getData().x;
    this.y1 = this.angularCropper.cropper.getData().y;
    this.x2 = this.angularCropper.cropper.getData().x + this.angularCropper.cropper.getData().width;
    this.y2 = this.angularCropper.cropper.getData().y + this.angularCropper.cropper.getData().height;

    //console.log(this.angularCropper.cropper.getData());
  }
}
