import { Component, OnInit } from '@angular/core';
import { Model } from '../shared/model';
import { GetModelsService } from '../services/get-models.service';

@Component({
  selector: 'app-models-display',
  templateUrl: './models-display.component.html',
  styleUrls: ['./models-display.component.scss']
})
export class ModelsDisplayComponent implements OnInit {

  models: Model[];
  errMess: string;
  showSpinner: boolean = true;
  onUpgrade: boolean = false;
  constructor(private getModelService: GetModelsService) {

  }

  ngOnInit(): void {
    if (!('indexedDB' in window)) {
      alert('This browser doesn\'t support IndexedDB! Models display will be slow on reload!');
      this.getModelService.getModels()
        .subscribe((models) => {
          this.models = models;
          this.showSpinner = false;
        },
          (errmess) => this.errMess = <any>errmess);

    } else {
      this.useIDBModels();
    }
  }

  onRefresh() {
    this.showSpinner = true;
    this.getModelService.getModels()
      .subscribe((models) => {
        this.models = models;
        this.showSpinner = false;

        let dbOpenRequest = indexedDB.open('models', 1);
        dbOpenRequest.onupgradeneeded = () => {
          console.log('Models IDB not found, Creating.')

          let db = dbOpenRequest.result;
          if (!db.objectStoreNames.contains('models_array')) {
            db.createObjectStore('models_array', { keyPath: 'model_id' })
          }
        }

        dbOpenRequest.onsuccess = () => {
          let db = dbOpenRequest.result;
          let transaction = db.transaction('models_array', 'readwrite');
          let models_array = transaction.objectStore('models_array');

          let idb_model_keys = models_array.getAllKeys()
          idb_model_keys.onsuccess = () => {
            for (let i = 0; i < models.length; i++) {
              if (!idb_model_keys.result.includes(models[i]['model_id'])) {
                let insertRequest = models_array.add(models[i])

                insertRequest.onsuccess = () => {
                  console.log('Model ', models[i]['model_id'], ' inserted into IDB.');
                }
              } else {
                let updateRequest = models_array.put(models[i]);
              }
            }
          }
        }
      },
        (errmess) => this.errMess = <any>errmess);
  }

  useIDBModels() {
    // let deleteRequest = indexedDB.deleteDatabase('models');
    let dbOpenRequest = indexedDB.open('models', 1);

    dbOpenRequest.onupgradeneeded = (event) => {
      console.log('Models IDB not found, Fetching and creating.')
      this.onUpgrade = true;
      let db = dbOpenRequest.result;
      if (!db.objectStoreNames.contains('models_array')) {
        db.createObjectStore('models_array', { keyPath: 'model_id' })
      }

      this.getModelService.getModels()
        .subscribe((models) => {
          this.models = models;
          this.showSpinner = false;
          let transaction = db.transaction('models_array', 'readwrite');
          let models_array = transaction.objectStore('models_array');
          for (let i = 0; i < models.length; i++) {
            let insertRequest = models_array.add(models[i])

            insertRequest.onsuccess = () => {
              console.log('Model ', models[i]['model_id'], ' inserted into IDB.');
            }
          }
        },
          (errmess) => this.errMess = <any>errmess);
    }

    dbOpenRequest.onsuccess = () => {
      console.log('Now using data from Models IDB.')
      let db = dbOpenRequest.result;
      let transaction = db.transaction('models_array', 'readwrite');
      let models_array = transaction.objectStore('models_array');
      let idb_models = models_array.getAll()
      idb_models.onsuccess = () => {
        // console.log(idb_models.result)
        this.models = idb_models.result;
        if(!this.onUpgrade){
        this.showSpinner = false;
        }
      }
    }
  }

  deleteModel(modelID) {
    this.getModelService.deleteModelfromID(modelID)
      .subscribe(() => {
        let dbOpenRequest = indexedDB.open('models', 1);
        dbOpenRequest.onsuccess = () => {
          let db = dbOpenRequest.result;
          let transaction = db.transaction('models_array', 'readwrite');
          let models_array = transaction.objectStore('models_array');
          models_array.delete(modelID)
        }
        this.ngOnInit()
      });
  }
}




