<p-toolbar>
    <div class="p-toolbar-group-left">

        <p-button type="button" (click)="visibleSidebar1 = true" icon="pi pi-arrow-right"></p-button>

        <!-- <p-button label="New" icon="pi pi-plus" class="p-mr-2"></p-button>
        <p-button label="Upload" icon="pi pi-upload" styleClass="p-button-success"></p-button>
            
        <i class="p-toolbar-separator pi pi-bars p-mr-2" style="vertical-align: middle"></i>
        
        <p-splitButton label="Save" icon="pi pi-check" [model]="items" styleClass="p-button-warning"></p-splitButton> -->
    </div>

    <div class="p-toolbar-group-right">
        <!-- <p-button icon="pi pi-search" class="p-mr-2"></p-button>
        <p-button icon="pi pi-calendar" styleClass="p-button-success p-mr-2"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-danger"></p-button> -->
    </div>
</p-toolbar>

<p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">
    <h1 style="font-weight:normal">Ingenium</h1>
    <ul>
        <li><a (click)="visibleSidebar1 = false" routerLink="/">Welcome</a></li>
        <li><a (click)="visibleSidebar1 = false" routerLink="/upload-image">Upload Image</a></li>
        <li><a (click)="visibleSidebar1 = false" routerLink="/models">Models</a></li>
        <li><a (click)="visibleSidebar1 = false" routerLink="/batch-process">Batch Processing</a></li>
    </ul>
</p-sidebar>

<router-outlet></router-outlet>