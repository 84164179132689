import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {MenuItem} from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [`
  :host ::ng-deep button {
      margin-right: .25em;
  }
  :host ::ng-deep li {
    margin-bottom: 20px;
    list-style: none;
    font-size: 1.1rem;
  }
  :host ::ng-deep a {
    text-decoration: none;
    color: #B3B3B3;
    transition: 400;
  }
  :host ::ng-deep a:hover {
    color: #FFFFFF;
  }
`]
})
export class AppComponent {
  visibleSidebar1;

  items: MenuItem[];
    
  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;

  //   this.items = [
  //     {
  //         label: 'Update',
  //         icon: 'pi pi-refresh'
  //     },
  //     {
  //         label: 'Delete',
  //         icon: 'pi pi-times'
  //     },
  //     {
  //         label: 'Angular Website',
  //         icon: 'pi pi-external-link',
  //         url: 'http://angular.io'
  //     },
  //     {
  //         label: 'Router',
  //         icon: 'pi pi-upload',
  //         routerLink: '/fileupload'
  //     }
  // ];
  }
}
