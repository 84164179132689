<div class="upload-wrapper">
    <h3 style="color:white;">Select a Model for Batch Processing</h3>
    <p-dropdown [options]="models" [(ngModel)]="selectedModel" optionLabel="name"></p-dropdown>
    <h3 style="color:white; margin-top:3%">Upload a ZIP file for Batch Processing</h3>
    <p-fileUpload name="demo[]" customUpload="true" (uploadHandler)="onUpload($event)" accept=".zip"
        maxFileSize="50000000000">
        <ng-template pTemplate="content" let-files>
        </ng-template>
    </p-fileUpload>
</div>

<div class="center" *ngIf="showSpinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        animationDuration=".5s"></p-progressSpinner>
</div>