import { Injectable } from '@angular/core';
import { Model } from '../shared/model';
// import { MODELS } from "../shared/models";
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';

import { API_URL } from '../api-variables/urls';

@Injectable({
  providedIn: 'root'
})
export class GetModelsService {

  constructor(private http:HttpClient,
    private processHTTPMsgService:ProcessHttpmsgService) { }

  getModels(): Observable<Model[]> {
    return this.http.get<Model[]>(API_URL+'get-all-models')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  
  getModelfromID(model_id): Observable<Model> {
    return this.http.get<Model>(API_URL+'get-coords?model_id='+model_id)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  deleteModelfromID(model_id): Observable<{}> {
    
    return this.http.delete(API_URL+'delete-coords?model_id='+model_id)
    .pipe(catchError(this.processHTTPMsgService.handleError));

  }
}
