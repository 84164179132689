<form action="" method="post" (submit)="onFormSubmit($event)">
    <div class="p-field p-grid">
        <label for="firstname3" class="p-col-fixed" style="width:100px; color: white;">Model ID</label>
        <div class="p-col">
            <input id="firstname3" name="modelId" type="text" pInputText>
        </div>
    </div>
    <div class="p-field p-grid">
        <div class="file-input">
            <input type="file" id="file" class="file" (change)="onBasicUpload($event)" >
            <label for="file">
              Select file
              <p class="file-name"></p>
            </label>
          </div>
    </div>
    <button id="uploadBtn" pButton type="submit">Upload Model</button>
</form>
<div id="displayImg">
    <h2 style="color: white;">Preview:</h2>
    <img [src]="url">
</div>