<div class="center" *ngIf="showSpinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
        animationDuration=".5s"></p-progressSpinner>
</div>
<div style="margin-top:2%; margin-bottom:1%; text-align:center" *ngIf="!showSpinner">
    <p-button (click)="onRefresh()" label="Refresh" icon="pi pi-refresh" styleClass="p-button-info"></p-button>
</div>

<div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center;">

    <p-card *ngFor="let model of models" [header]="model.model_id" subheader="Version {{model.version}}"
        [style]="{width: '360px', margin:'20px'}" styleClass="p-card-shadow">
        <ng-template pTemplate="header">
            <img alt="Card" style="border-radius: 5px;" [src]="model.image">
        </ng-template>
        <p>Updated By: {{model.updated_by}}<br>Date Modified: {{model.date_modified}}</p>
        <ng-template pTemplate="footer">
            <p-button label="Edit" icon="pi pi-pencil" [routerLink]="['/edit-model']"
                [queryParams]="{model_id:model.model_id}" styleClass="p-button-success"></p-button>
            <p-button label="Delete" icon="pi pi-ban" (onClick)="deleteModel(model.model_id)"
                styleClass="p-button-danger"></p-button>
            <p-button label="OCR" icon="pi pi-compass" [routerLink]="['/ocr']"
                [queryParams]="{model_id:model.model_id}"></p-button>

        </ng-template>
    </p-card>


</div>