import { Injectable } from '@angular/core';
import { CropArea } from '../shared/cropArea'
import { CropTest } from '../shared/cropTest'
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';

import { API_URL } from '../api-variables/urls';
import { OCR_API_URL } from '../api-variables/urls';

@Injectable({
  providedIn: 'root'
})
export class EditModelService {

  constructor(private http:HttpClient,
    private processHTTPMsgService:ProcessHttpmsgService) { }

    putCrop(crop: CropArea):Observable<CropArea>{
      const httpOptions={
        headers:new HttpHeaders({
          'Content-Type':'application/json'
        })
      };
  
      return this.http.put<CropArea>(API_URL+"update-coords?model_id="+crop.model_id, crop, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
    }

    testCrop(cropTest: CropTest):Observable<{msg: string}>{
      const httpOptions={
        headers:new HttpHeaders({
          'Content-Type':'application/json'
        })
      };

      return this.http.post<{msg: string}>(OCR_API_URL+"test-model", cropTest, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));

    }

    testPreProcess(preProcessTest: {}):Observable<{image: string}>{
      const httpOptions={
        headers:new HttpHeaders({
          'Content-Type':'application/json'
        })
      };

      return this.http.post<{image: string}>(OCR_API_URL+"test-preprocess", preProcessTest, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));

    }
  

}
