import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { API_URL } from '../api-variables/urls';


@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  url: any;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onBasicUpload(event) {
    if (event.target.files[0].type.includes("image/")) {
      console.log("Image Uploaded")
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.url = reader.result;
        document.getElementById('displayImg').style.display = "block";
      }
    }
    else {
      alert("Only images allowed");
    }
  }

  onFormSubmit(event) {
    console.log("onFormSubmit");
    const modelID = event.target[0].value;
    const modelImage = event.target[1].files[0];
    let reader = new FileReader();
    reader.readAsDataURL(modelImage);
    reader.onload = (_event) => {
      this.url = reader.result;
      this.SubmitData(modelID, this.url);
    }
  }
  SubmitData(model, img) {
    // console.log(model, img);
    let api_url = API_URL+"insert-coords"
    const data = {
      "model_id": model,
      "updated_by": "User",
      "values": [{
        'fieldName' : 'HDG',
        'fieldValue' : {
          'x1':0,
          'y1':0,
          'x2':0,
          'y2':0
        }
      }],
      "image": img
    }
    this.http.post(api_url, data).toPromise()
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }
}
