import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ModelsDisplayComponent } from './models-display/models-display.component';
import { EditModelComponent } from './edit-model/edit-model.component';
import { OcrComponent } from './ocr/ocr.component';
import { BatchProcessComponent } from './batch-process/batch-process.component';

const routes: Routes = [
  {path:'upload-image' , component: UploadImageComponent},
  {path:'models' , component: ModelsDisplayComponent},
  {path:'edit-model' , component: EditModelComponent},
  {path:'ocr' , component: OcrComponent},
  {path:'batch-process', component: BatchProcessComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
