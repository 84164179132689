<form action="" method="post" enctype="multipart/form-data" (submit)="onFormSubmit($event)">
    <div style="display: flex; align-items: center;">
        <label for="firstname3" style="color: white; margin: 10px;">Model ID</label>
        <h3 id="modelID" [innerText]="getModelID()" style="color: white;margin: 10px;"></h3>
    </div>
    <div class="p-field p-grid">
        <div class="file-input">
            <input type="file" id="file" name="file" class="file" (change)="onBasicUpload($event)" >
            <label for="file">
              Select file
              <p class="file-name"></p>
            </label>
          </div>
    </div>
    <button id="uploadBtn" *ngIf="showUpload" pButton type="submit">Test Image</button>
</form>
<div *ngIf="showSpinner" style="margin-top: 2%; text-align:center">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
</div>

<div *ngIf="showPreview" id="displayImg">
    <h2 style="color: white;">Preview:</h2>
    <img [src]="url">
</div>

<app-result-table *ngIf="showResult" [extractedText]="extractedText"></app-result-table>