import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OCR_API_URL } from '../api-variables/urls';

@Component({
  selector: 'app-ocr',
  templateUrl: './ocr.component.html',
  styleUrls: ['./ocr.component.scss']
})
export class OcrComponent implements OnInit {

  url: any;
  showUpload: boolean = false;
  showPreview: boolean = false;
  showResult: boolean = false;
  showSpinner: boolean = false;
  extractedText: {};

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }
  getModelID() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const modelID = urlParams.get('model_id')
    return modelID;
  }
  onBasicUpload(event) {
    if (event.target.files[0].type.includes("image/")) {
      console.log("Image Uploaded")
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.url = reader.result;
        this.showPreview = true;
        this.showUpload = true;
      }
    }
    else {
      alert("Only images allowed");
    }
  }

  onFormSubmit(event) {
    console.log("onFormSubmit");
    this.showSpinner = true;
    const modelID = this.getModelID();
    const modelImage = event.target[0].files[0];
    let reader = new FileReader();
    reader.readAsDataURL(modelImage);
    reader.onload = (_event) => {
      this.url = reader.result;
      this.SubmitData(modelID, this.url);
    }
  }
  SubmitData(model, img) {
    this.showResult = false;
    let api_url = OCR_API_URL + "run-model"
    const data = {
      "model_id": model,
      "image": img
    }
    this.http.post(api_url, data).toPromise()
      .then((res) => {
        this.showResult = true;
        this.showPreview = false;
        this.extractedText = res;
        console.log(res);
        this.showSpinner = false;
      })
      .catch(err => console.log(err))
  }

}
