<p>edit-model works!</p>
<div style="display: flex; justify-content: space-around;">
    <h3 style="color: white;">Model ID: {{model.model_id}}</h3>
    <p-button label="Parameters" (click)="showParameter()" icon="pi pi-check-circle" styleClass="p-button-secondary"></p-button>

</div>

<p-dialog [(visible)]="dialogDisplay" maximizable="true">
    <ng-template pTemplate="header">
        Are You Sure You Want to Save the Following Crop Area?
    </ng-template>
    X1: {{x1}}<br>
    Y1: {{y1}}<br>
    X2: {{x2}}<br>
    Y2: {{y2}}<br><br>

    Crop Name: {{cname}}
    <p-footer>
        <p-button label="Confirm" (click)="save()" icon="pi pi-check-circle" styleClass="p-button-secondary"></p-button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="preProcessedOP">
    <ng-template pTemplate="header">
        Image After Pre Processing?
    </ng-template>
    <img [src]="processedImage">

</p-dialog>

<p-dialog [(visible)]="ocrDisplay">
    <ng-template pTemplate="header">
        The Result of the OCR for the given Crop: 
    </ng-template>
    {{ocrText}}
    <p-footer>
        <!-- <p-button label="Confirm" (click)="save()" icon="pi pi-check-circle" styleClass="p-button-secondary"></p-button> -->
    </p-footer>
</p-dialog>


<p-dialog [(visible)]="parameterDisplay" maximizable="true">
    <ng-template pTemplate="header">
        {{model.model_id}} Parameters 
    </ng-template>
        <ng-template pTemplate="body">

            <table cellspacing="20px">
                <tr>
                    <th>
                        Crop Name
                    </th>
                    <th>
                        X1
                    </th>
                    <th>
                        Y1
                    </th>
                    <th>
                        X2
                    </th>
                    <th>
                        Y2
                    </th>
                </tr>
                <tr *ngFor="let value of model.values">
                    <td>
                        {{value.fieldName}}
                    </td>
                    <td>
                        {{value.fieldValue.x1}}
                    </td>
                    <td>
                        {{value.fieldValue.y1}}
                    </td>
                    <td>
                        {{value.fieldValue.x2}}
                    </td>
                    <td>
                        {{value.fieldValue.y2}}
                    </td>
                </tr>
            </table>

        </ng-template>

</p-dialog>


<div class="grid-container">
    <div class="item1">
        <angular-cropper #angularCropper  [cropperOptions]="config" [imageUrl]="imageUrl"></angular-cropper>
    </div>
    <div class="item2">
        <img style="height: auto; width: 90%;" [src]="imgUrl"/>
    </div>
    <div class="item3">
        <h4 style="color: #B3B3B3;">Model Parameters</h4>
        <table style="margin:auto;">
            <tr>
                <td>
                    <label for="X1" style="color: #B3B3B3;">X1 </label>
                </td>
                <td>
                    <input id="X1" type="text" [placeholder]="x1" pInputText disabled /><br>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="Y1" style="color: #B3B3B3;">Y1 </label>
                </td>
                <td>
                    <input id="Y1" type="text" [placeholder]="y1" pInputText disabled /><br>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="X2" style="color: #B3B3B3;">X2 </label>
                </td>
                <td>
                    <input id="X2" type="text" [placeholder]="x2" pInputText disabled /><br>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="Y2" style="color: #B3B3B3;">Y2 </label>
                </td>
                <td>
                    <input id="Y2" type="text" [placeholder]="y2" pInputText disabled />
                </td>
            </tr>
        </table>
        <span style="margin:10%;" class="p-float-label">
            <input id="crop-name" #cropName style="width:-webkit-fill-available;" type="text" pInputText> 
            <label style="font-size: large;" for="float-input">Crop Name</label>
        </span>
        <div class="p-field-checkbox" style="justify-content: center; color: #B3B3B3;">
            <p-checkbox [(ngModel)]="preProcessChecked" binary="true" inputId="preProcessBinary"></p-checkbox>
            <label for="preProcessBinary">PreProcess</label>
        </div>
        <div *ngIf="preProcessChecked" class="p-field-checkbox" style="justify-content: center; color: #B3B3B3;">
            <p-checkbox [(ngModel)]="noiseReductionChecked" binary="true" inputId="noiseReductionbinary"></p-checkbox>
            <label for="noiseReductionbinary">Reduce Noise</label>
        </div>
        <div *ngIf="noiseReductionChecked" style="color: #B3B3B3; width: 80%; display: inline-block; margin-bottom: 10%;">
            <p>Noise Reduction: {{noise}}</p>
            <p-slider style="color: golden;" [(ngModel)]="noise"></p-slider>
        </div>
        <p-button *ngIf="preProcessChecked" label="Test PreProcess" (click)="testPreProcess()" icon="pi pi-sliders-h" style = "margin-bottom: 10%;" styleClass="p-button-primary"></p-button><br>

        <div *ngIf="showSpinner" style="margin-bottom: 2%;">
            <p-progressSpinner [style]="{width: '30px', height: '30px'}" styleClass="custom-spinner" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
        </div>
        <p-button label="Test Crop" (click)="testCrop()" icon="pi pi-sliders-h" styleClass="p-button-primary"></p-button>
        <p-button label="Save" (click)="showDialog()" icon="pi pi-check-circle" styleClass="p-button-secondary"></p-button><br>
        <p-button label="OCR" icon="pi pi-compass" [routerLink]="['/ocr']" styleClass="p-button-info" [queryParams]="{model_id:model.model_id}"></p-button>

    </div>
   
</div>