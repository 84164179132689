import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OCR_API_URL, API_URL } from '../api-variables/urls';

@Component({
  selector: 'app-batch-process',
  templateUrl: './batch-process.component.html',
  styleUrls: ['./batch-process.component.scss']
})
export class BatchProcessComponent implements OnInit {

  models:any[] = [];
  selectedModel:any;
  showSpinner: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    let apiUrl = API_URL+'get-model-ids'
    try {
      this.http.get(apiUrl).subscribe((data)=>{
        let keys = Object.keys(data);
        for(let i=0; i<keys.length; i++){
          let obj:any = {};
          obj['name'] = data[keys[i]]
          this.models.push(obj)
        }
        this.selectedModel = this.models[0];
      })
    }catch(e) {
      console.log(e)
    }
  }

  async onUpload($event) {
    this.showSpinner = true;
    let zipFile = new Blob([$event.files[0]], { type: 'application/zip' })
    let formData = new FormData();
    formData.append('model_id', this.selectedModel.name)
    formData.append('zipFile', zipFile, $event.files[0].name)
    try {
      let apiUploadURL = OCR_API_URL + 'bulk-process'

      let HTTPOptions = {
        headers: new HttpHeaders({
          'Accept': 'text/csv'
        }),
        'responseType': 'blob' as 'json'
      }

      let response = await this.http.post(apiUploadURL, formData, HTTPOptions).toPromise()

      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(response);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "result.csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      this.showSpinner = false;
    } catch (e) {
      console.error('Kindly check the Model ID :)')
      this.showSpinner = false;
    }
  }

}
