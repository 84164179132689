import { Component, OnInit, Input } from '@angular/core';
import { DownloadCSVService } from '../../services/download-csv.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-result-table',
  templateUrl: './result-table.component.html',
  styleUrls: ['./result-table.component.scss']
})
export class ResultTableComponent implements OnInit {

  @Input() extractedText: any;

  pdfTablehead = [['Field Name', 'Field Value']]

  tableContent: any[] = [];

  constructor(private downloadService: DownloadCSVService) { }

  ngOnInit(): void {
    this.tableContent = this.extractedText
  }

  onDownloadCSVClick($event) {
    this.downloadService.downloadFile(this.tableContent, 'extracted-text');
  }

  createPdf() {
    var doc = new jsPDF();

    doc.setFontSize(18);
    doc.setFontSize(11);
    doc.setTextColor(100);


    (doc as any).autoTable({
      head: this.pdfTablehead,
      body: this.objectToArray(this.tableContent),
      theme: 'striped',
    })
    doc.save('extracted-text.pdf');
  }

  objectToArray(obj) {
    let rows = [];
    obj.forEach(element => {
      var temp = [element.fName, element.fVal];
      rows.push(temp);
    });
    return rows;
  }

}
