import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {SidebarModule} from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {CheckboxModule} from 'primeng/checkbox';
import {SliderModule} from 'primeng/slider';

import {DropdownModule} from 'primeng/dropdown';

import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { UploadImageComponent } from './upload-image/upload-image.component';
import { ModelsDisplayComponent } from './models-display/models-display.component';
import { EditModelComponent } from './edit-model/edit-model.component';

import { GetModelsService } from './services/get-models.service';
import { ProcessHttpmsgService } from './services/process-httpmsg.service';
import { EditModelService } from './services/edit-model.service';
import { DownloadCSVService } from './services/download-csv.service';

import { OcrComponent } from './ocr/ocr.component';
import { ResultTableComponent } from './ocr/result-table/result-table.component';
import { BatchProcessComponent } from './batch-process/batch-process.component';


@NgModule({
  declarations: [
    AppComponent,
    UploadImageComponent,
    ModelsDisplayComponent,
    EditModelComponent,
    OcrComponent,
    ResultTableComponent,
    BatchProcessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    SidebarModule,
    ButtonModule,
    ToolbarModule,
    SplitButtonModule,
    CardModule,
    FileUploadModule,
    HttpClientModule,
    AngularCropperjsModule,
    InputTextModule,
    DialogModule,
    TableModule,
    ProgressSpinnerModule,
    FileUploadModule,
    CheckboxModule,
    SliderModule,
    DropdownModule
  ],
  providers: [
    GetModelsService,
    ProcessHttpmsgService,
    EditModelService,
    DownloadCSVService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
