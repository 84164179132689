<div class="tableWrapper">
    <p-table [value]="tableContent" styleClass="p-datatable-gridlines" [paginator]="true" [rows]="5"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[5,10,15]">
        <ng-template pTemplate="caption">
            Extracted Text
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Field Name</th>
                <th>Field Value</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eText>
            <tr>
                <td>{{eText.fName}}</td>
                <td>{{eText.fVal}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="downloadButtons">
    <button style="margin-right: 5%;" pButton (click)="onDownloadCSVClick($event)" type="button" label="Download CSV"
        class="p-button-info p-button"></button>

    <button pButton (click)="createPdf()" type="button" label="Download PDF" class="p-button-info p-button"></button>
</div>