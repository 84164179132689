//Use this on server
let API_URL: string = 'http://lowcodemlplatform.eastus.cloudapp.azure.com:5000/api/'
let OCR_API_URL: string = 'http://lowcodemlplatform.eastus.cloudapp.azure.com:5001/api/';

//Use this in dev
// let API_URL: string = 'http://localhost:5000/api/'
// let OCR_API_URL: string = 'http://localhost:5001/api/';

export { API_URL, OCR_API_URL }

